.logo {
  grid-area: logo;
}
.search_div {
  grid-area: searchBar;
}
.header_container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 0 0.2rem 0.3rem 0rem rgba(228, 228, 228, 0.488);
  /* transition: 0.3s; */
  z-index: 100;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem;
  /* margin-bottom: 0.3rem; */
  width: 80%;
  background-color: #ffffff;
  z-index: 100;
}

/* Logo styles */
.logo {
  /* width: 25%; */
  margin-left: .9rem;
  display: flex;
  /* border: 1px solid; */
  /* padding-left: 1rem;
  padding-right: 1rem; */
  /* justify-content: center; */
  /* border: 1px solid; */
  /* align-items: center; */
}
.logo a {
  display: flex;
  align-items: center;
}
.logo img {
  width: 80px;
  height: 26px;
  /* border-radius: 22px; */
}
/* .logo span {
  font-size: 23px;
  font-weight: bold;
  color: rgb(65, 68, 67);
  padding-left: 0.7rem;
} */

/* Search bar styles */
.search_div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: end;

  /* border: 1px solid; */
  padding-left: 1rem;
  padding-right: 1rem;
}

.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}

/* Login button styles */

/* Media queries for responsiveness */
@media screen and (max-width: 1024px) {
  .header {
    width: 100%;
    /* border: 1px solid; */
    padding: 0.4rem;
  }
  .grid-container {
    display: grid;
    grid-template-areas:
      "logo  navigation"
      "searchBar searchBar";
  }
  .search_div {
    margin-top: 0.4rem;
    width: 100%;
  }
  .logo img {
    width: 75px;
    height: 28px;
  }
}

/* Add more media queries as needed */

/* header */
