.footer-container {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(36, 39, 41);
}
.footer-information {
  /* width: 95%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.footer-item{
  padding: 1rem
}

.footer-item-2,
.footer-item-3{
  width: 12%;
}

.footer-item-1,
.footer-item-4 {
  width: 15%;
}

.footer-item-5 {
  width: 18%;
  margin-left: 2rem;
}

.footer-item h3 {
  margin-bottom: 22px;
}
.footer-item li {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.footer-item h3,
.footer-item h2,
.footer-item span,
.footer-item li,
.footer-item p {
  color: white;
  cursor: pointer;
}
.footer-item-1 h2 {
  margin-bottom: 18px;
}
.footer-item h3 {
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.249);
}
.footer-social-icons {
  color: white;
  margin-top: 22px;
  display: flex;
  flex-direction: row;
}
.footer-social-icons div {
  cursor: pointer;
  background-color: rgb(38, 38, 41);
  padding: 3px;
  display: flex;
  margin-right: 10px;
}
.red-rectangle {
  width: 7px;
  height: 7px;
  background-color: red;
  margin-right: 10px; /* Space between rectangle and text */
}



.footer-copyright {
  display: flex; /* Enables Flexbox layout */
  flex-direction: column; /* Stacks items vertically */
  align-items: center; /* Centers items horizontally */
  justify-content: center; /* Centers items vertically */
  width: 100%; /* Ensures full width of the container */
  padding-top: 30px;
  padding-bottom: 35px;
  border-top: 1px solid rgba(255, 255, 255, 0.203);
}

.footer-copyright p {
  color: white;
  text-align: center; /* Centers the text within each paragraph */
  margin: 5px 0; /* Adds consistent spacing between paragraphs */
}

@media screen and (max-width: 786px) {
  .footer-information {
    flex-direction: column;
    align-items: center;
  }
  .footer-item {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    text-align: center;
    width: 90%;
    padding: 1rem;
  }
  .footer-item-5{
    width: 80%;
    height: 300px;
    margin-left: 0;
  }
  .footer-item-4 ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer-copyright {
    flex-direction: column;
    align-items: center;
  }
  .footer-copyright p {
    text-align: center;
    margin-top: 12px;
  }
}
