/* PrivacyPolicyPage.css */

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.fade-in.visible {
  opacity: 1;
}

.privacy-policy-container {
  font-family: 'Arial', sans-serif;
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
  color: #333;
  background-color: #f0f0f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
}

.section-title {
  font-size: 1.8em;
  margin-top: 20px;
  color: #555;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

section {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.privacy-policy-container p {
  line-height: 1.6;
  font-size: 1.1em;
}

.privacy-policy-container a {
  color: #0066cc;
  text-decoration: none;
}

.privacy-policy-container a:hover {
  text-decoration: underline;
}

.privacy-policy-container ul {
  margin: 10px 0;
  padding-left: 20px;
}

.privacy-policy-container li {
  margin-bottom: 10px;
  list-style-type: disc;
}

.privacy-policy-container strong {
  color: #0066cc;
}

/* Responsive design */
@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 10px;
  }

  .main-title {
    font-size: 2em;
  }

  .section-title {
    font-size: 1.5em;
  }
}