.contact_bar {
  width: 100%;
  display: flex;
  padding: 0.3rem;
  background-color: rgb(3, 163, 137);
}

.contact_bar_tag {
  display: flex;
  justify-content: center;
  width: 40%;
}

.contact_bar_tag span {
  color: white;
  font-weight: 400;
}

.contact_bar_info {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-around;
}

.contact-item {
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  font-size: 1rem;
}

.tilted-line {
  width: 1px;
  background-color: rgb(255, 255, 255);
  transform: rotate(20deg);
}

.contact-item-4 {
  display: flex;
  align-items: center;
}

.contact-social-media {
  display: flex;
  align-items: center;
  margin-left: 0.5rem; /* Space between "Follow" text and icons */
}

.contact-social-media a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px; /* Space between icons */
}

@media screen and (max-width: 1000px) {
  .contact_bar {
    flex-direction: column;
    align-items: center;
  }
  .contact_bar_tag {
    width: 100%;
  }
  .contact_bar_info {
    width: 100%;
  }
}

@media screen and (max-width: 514px) {
  .contact_bar {
    /* display: none; */
  }
  .contact_bar_info {
    flex-direction: column;
    align-items: center;
    margin-top: 4px;
    display: none;
  }
}
