.login-div {
  /* min-height: auto; */
  /* padding: 5% 5%; */
  /* margin: 5%; */
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  transition: 0.2s ease-in-out;
  border-radius: 12px;
}
.login-close-btn {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  /* border: 1px solid; */
}
.login-close-btn div {
  cursor: pointer;
}
.login-div h2 {
  /* margin-top: 1rem; */
  margin-bottom: 0.4rem;
  color: gray;
}

.login-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  width: 90%;
  margin-bottom: 1.3rem;
  border-radius: 16px;
}
.login-input input {
  width: 88%;
  height: 2.8rem;
  border-radius: 16px;
  padding-left: 1rem;
  font-size: medium;
  border: none;
}
.login-input input:focus {
  outline: none !important;
  border-color: transparent !important;
}
.login-button {
  width: 90%;
}
.login-button button {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  font-size: medium;
  padding: 3%;
  background-color: rgb(5, 150, 104);
  color: white;
  border: none;
  /* background-color: transparent; */
  border-radius: 16px;
  cursor: pointer;
  margin-top: 0.8rem;
  /* border: 1px solid; */
}
.login-button button:hover {
  background-color: rgb(5, 117, 81);
  color: white;
}

.login-with-otp button {
  background-color: white;
  border: 1px solid gray;
  color: rgb(129, 128, 128);
  font-size: medium;
}
.login-with-otp button:hover {
  background-color: rgb(252, 206, 121);
  color: white;
  border: none;
}

.login-change-option {
  width: 100%;
  padding: 0.8rem;
  display: flex;
}
.login-change-option button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.login-change-option:hover button {
  color: rgb(10, 94, 9);
}

/* Sign Up  */
.signup-name-div {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.signup-input {
  width: 48%;
}
.signup-input input {
  width: 80%;
}
.login-otp-div .login-otp-button {
  width: 90%;
}
.login-otp-button button {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  font-size: large;
  background-color: rgb(5, 150, 104);
  color: white;
  border: none;
  /* background-color: transparent; */
  border-radius: 25px;
  cursor: pointer;
  margin-top: 1rem;
}
.login-otp-div {
  /* width: 25rem; */
  min-height: 18rem;
  padding: 3% 3%;
  margin: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  transition: 0.2s ease-in-out;
  border-radius: 12px;
}
.login-otp-text {
  margin-top: 1rem;
  width: 100%;
}
.login-otp-text button {
  width: auto;
  border: none;
  color: rgb(10, 96, 10);
  background-color: transparent;
  font-size: medium;
  cursor: pointer;
}
.login-otp-text-msg {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  color: #8c8e8c;
}
/* Apply styles to the MuiOtpInput input elements */
.MuiOtpInput-Box input {
  border-color: #23cf10 !important;
}

/* .MuiInputBase-input{} */

/* .MuiOtpInput-Box input:focus {
  border: none;
} */

@media screen and (max-width: 1200px) {
}
