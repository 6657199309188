@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
}

html {
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-thumb {
  background: rgb(3, 163, 137);
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background: rgb(227, 119, 18);
}

@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

.zoom-container {
  transition: transform 0.5s ease;
}
.zoom-container:hover {
  transform: scale(1.05);
}

.image-zoom-container {
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ease;
}

.image-zoom-container img {
  transition: transform 0.5s ease;
}

.image-zoom-container:hover img {
  transform: scale(1.2);
}

img {
  width: 100%;
  /* height: 100%; */
}
.no-scroll {
  overflow: hidden;
}

/* Hide spinners in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinners in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Optional: additional styling to improve appearance */
/* input[type="number"] {
  appearance: none;
  border: 1px solid #ccc;
  padding: 0.5em;
  font-size: 1em;
  width: 100px;
} */

.react-tel-input .country-list::-webkit-scrollbar {
  width: 4px; /* Chrome, Safari, Opera */
}

.react-tel-input .country-list::-webkit-scrollbar-thumb {
  background: rgb(55, 100, 235);
  border-radius: 10px;
}

.react-tel-input .country-list::-webkit-scrollbar-thumb:hover {
  background: #6f6f6f;
}

.transition-height {
  transition: max-height 0.5s ease-in-out;
}

/* fade-in fade-out animation */

/* App.css */

/* App.css */

.fade-in {
  animation: fadeIn 0.8s ease-in-out forwards;
}

.fade-out {
  animation: fadeOut 0.8s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.visible {
  opacity: 1;
}

