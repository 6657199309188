.navbar {
  display: flex;
  /* align-items: center; */
  padding: 0.1rem;
  width: 100%;
  /* border: 1px solid; */
  /* padding-bottom: 1rem; */
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav-menu {
  display: flex;
  /* justify-content: space-between; */ 
  align-items: center;
  /* border: 1px solid; */
}

.nav-link {
  margin-right: 20px;
  font-size: 18px;
  color: #555;
}

.nav-link:hover {
  color: rgb(3, 163, 137);
}

.login-button a {
  background-color: rgb(254, 74, 85);
  color: #fff;
  padding: 3px 18px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.1rem;
}
.login-button a:hover {
  color: white;
  background-color: rgb(3, 163, 137);
}
.dashboard-button a {
  background-color: rgb(104, 138, 241);
  color: #fff;
  padding: 3px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-button a:hover {
  color: white;
  background-color: rgb(55, 100, 235);
}

.drawer-bar {
  display: none;
}
.drawer {
  display: none;
}

@media only screen and (max-width: 768px) {
  .navbar {
    display: none;
  }
  .drawer-bar {
    /* border: 1px solid; */
    display: flex;
    justify-content: end;
    padding-right: 10px;
    align-items: center;
  }
  .drawer {
    display: block;
  }
}
